import React from "react";
import { Link } from "gatsby";
import "./../styles/success.scss";
import * as queryString from "query-string";
import SEO from "../components/seo"
//Icon
import { BiArrowBack} from "react-icons/bi";
import { FcApproval } from "react-icons/fc";

//SVG
import LogoWhite from "./../images/logo-white.svg";

const Success = ({location}) => {

    let params = queryString.parse(location.search);
    let lang = params.lang || 'es';
    
    let data = {
        "es" : {
            title: "¡Gracias!",
            message: "Tu solicitud de contacto ha sido recibida.",
            linkMesssage : "Regresar",
            backURL : "/" 
        },
        "en": {
            title: "Thank you!",
            message: "Your message has been received.",
            linkMesssage: "Go back",
            backURL: "/en/" 
        }
    }

    return(
        <div className="succes-page">
            <SEO title="Success" />
            <div className="message">
                <h1>{data[lang].title} <FcApproval size="28" /> </h1>
                <span>{data[lang].message}</span>
                <Link to={data[lang].backURL} className="link"> <BiArrowBack size="18" /> {data[lang].linkMesssage}</Link>
            </div>
            <img src={LogoWhite} className="img" alt=""/>
        </div>
    )
}

export default Success;